<template>
  <div>
    <div v-if="target_type==2">
      <div class="back_view" @click="backSeek" v-if="showPdf">返回查看</div>
      <div class="pdf_cont" v-if="!showPdf">
        <img src="../../static/images/pdf.png" alt="">
        <div class="title">{{ pdfData.title }}</div>
        <div class="preview_seek">
          <span @click="previewSeek">预览查看</span>
        </div>
        <div class="preview_seek">
          <span @click="downloadPdf">下载PDF</span>
        </div>
      </div>
      <a-modal v-model="userInfo">
        <div class="userInfo">
          <img :src="pdfData.employeeAvatar" alt="">
          <div>
            <div style="    font-weight: bold;
    font-size: 16px;">{{ pdfData.employeeName }}
            </div>
            <span style="    color: #9b9b9b;
    font-size: 12px;">{{ pdfData.employeeMobile }}</span>
          </div>
        </div>
        <div class="userQrcode">
          <img :src="pdfData.qrCode" alt="">
          <span style="margin-top: 10px;font-size: 12px;color: #9b9b9b;">长按二维码，加我企业微信</span>
        </div>
      </a-modal>
    </div>
    <div v-if="target_type==3" class="article_page">
      <h2 class="article_title">{{ articleData.title }}</h2>
      <p><span class="">{{ articleData.title }}</span></p>
      <p><span class="article_author">{{ articleData.author }}</span></p>
      <div v-html="articleData.content" id="show_content"></div>
    </div>
    <div v-show="target_type==2 && showPdf">
      <pdf
          v-for="i in numPages"
          :key="i"
          :src="pdfDataList"
          :page="i"
      />
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import {getRadarApi, openUserInfoApi, getRadar} from "../../api/radar";
import {debounce} from '../../plugins/debounce'
import axios from "axios"

export default {
  components: {
    pdf
  },
  data() {
    return {
      url: window.location.href,
      //  微信用户数据
      showPdf: false,
      weChatUserData: {},
      link: '',
      //pdf数据
      pdfData: {},
      //文章数据
      articleData: {},
      openTime: "",
      clickId: "",
      userInfo: false,
      pdfDataList: '',
      numPages: undefined,
      isPageBack: false
    }
  },
  created() {
    //  获取参数
    this.target_type = this.$route.query.type;
    this.staff_id = this.$route.query.employee_id;
    this.radar_id = this.$route.query.id;
    this.target_id = this.$route.query.target_id;
    this.getUserData()
    this.openTime = new Date().getTime();
  },
  mounted() {
    const isIos = /ipad|iPhone|Mac/i.test(window.navigator.userAgent)

    if(isIos) {
      window.addEventListener("scroll",this.handleScroll);
    }else{
      document.addEventListener('visibilitychange', this.beforeunloadFn);
    }

  },
  destroyed() {
    const isIos = /ipad|iPhone|Mac/i.test(window.navigator.userAgent)
alert(isIos)
    if(isIos) {
      window.removeEventListener('scroll', this.handleScroll);
    }else{
      document.removeEventListener('visibilitychange', this.beforeunloadFn);
    }
  },
  methods: {
    beforeunloadFn(e) {
      console.trace()
      this.isPageBack = true
      const nDate = new Date().getTime();
      const ntime = nDate - this.openTime
      const time = this.toHHmmss(ntime)
      const completionRate = this.getScrollTop()
      const readData = {
        nickname: this.weChatUserData.nickname,
        radarId: this.radar_id,
        linkId: this.target_id,
        employeeId: this.staff_id,
        clickId: this.clickId,
        readTime: time,
        finishRate: completionRate,
      }
      getRadar(readData).then(res => {
      })
    },
    //跳转下载
    downloadPdf() {
      window.location.href = this.pdfData.pdf
    },
    //返回查看
    backSeek() {
      this.showPdf = false
    },
    //预览查看
    previewSeek() {
      this.showPdf = true
    },
    //获取用户数据
    getUserData() {
      let that = this;
      openUserInfoApi({
        id: that.radar_id
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = '/auth/radar?id=' + that.radar_id + '&target=' + encodeURIComponent(that.url);
          that.$redirectAuth(redirectUrl);
        }
        this.weChatUserData = res.data;
        this.getRadarData();
      });

    },
    //  获取数据
    getRadarData() {
      let params = {
        union_id: this.weChatUserData.unionid,
        nickname: this.weChatUserData.nickname,
        avatar: this.weChatUserData.headimgurl,
        target_type: this.target_type,
        radar_id: this.radar_id,
        target_id: this.target_id,
        staff_id: this.staff_id
      }
      getRadarApi(params).then((res) => {
        this.clickId = res.data.clickId
        if (this.target_type == 1) {
          window.location.href = res.data.link
        } else if (this.target_type == 2) {
          this.pdfData = res.data
          this.pafData(res.data.pdf)
          if(this.pdfData.employeeCard == 1 && this.target_type == 2){
            this.userInfo = true
          }
          document.title = "雷达PDF"
        } else if (this.target_type == 3) {
          this.articleData = res.data.article
          document.title = "雷达文章"
        }
      })
    },
    //  浏览时间
    toHHmmss(data) {
      var time;
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.ceil((data % (1000 * 60)) / 1000);
      if (Number(hours)) {
        time = (hours < 10 ? ('0' + hours) : hours) + '时' + (minutes < 10 ? ('0' + minutes) : minutes) + '分' + (seconds < 10 ? ('0' + seconds) : seconds) + "秒";
      } else if (Number(minutes)) {
        time = (minutes < 10 ? ('0' + minutes) : minutes) + '分' + (seconds < 10 ? ('0' + seconds) : seconds) + "秒";
      } else {
        time = (seconds < 10 ? ('0' + seconds) : seconds) + "秒"
      }

      return time;
    },
    pafData(data){
      this.pdfDataList = pdf.createLoadingTask(data)
      this.pdfDataList.promise.then(pdf => {
        this.numPages = pdf.numPages;
      });
    },

    //  浏览程度
    getScrollTop() {
      var scroll_top = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scroll_top = document.documentElement.scrollTop;
      } else if (document.body) {
        scroll_top = document.body.scrollTop;
      }
      const allHeight = Math.max(document.documentElement.scrollHeight, document.documentElement.clientHeight);
      const lookHeight = document.body.clientHeight

      return ((lookHeight + scroll_top) / allHeight) * 100;
    },

    handleScroll(){
      const that = this
      debounce(() => {
        that.beforeunloadFn()
      }, 500)
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-modal-footer {
  display: none;
}
#show_content{
  /deep/img{
    width: 100%;
  }
}

.pdf_cont {
  padding-top: 57px;
  text-align: center;

  img {
    width: 75px;
    height: 75px;
  }

  .title {
    margin-top: 20px;
    color: #000;
    font-size: 17px;
  }
}

.back_view {
  padding-left: 25px;
  margin-top: 25px;
  font-size: 16px;
  display: inline-block;
}

.userInfo {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  img {
    width: 40px;
    margin-right: 10px;
    height: 40px;
  }
}

.userQrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  img {
    width: 200px;
    height: 200px;
  }
}

.preview_seek {
  margin-top: 15px;
  font-size: 16px;
}

.article_page {
  padding: 15px;
  width: 100%;
  overflow-x: hidden;
}

/deep/ #js_content {
  visibility: visible !important;
}

/deep/ .rich_media_content {
  visibility: visible !important;
}

.article_title {
  font-weight: bold;
}
</style>
